:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.bodyConsultationPolygon {
  background: linear-gradient(var(--cogno-white), var(--cogno-blue-dark));
  min-height: 100vh;
}

.bodyConsultationPolygon iframe{
  min-height: 100vh;
}

.secc_prin {
  min-height: 250px;
  max-width: 600px;
  margin: 8px auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--cogno-blue-lite);
  border-top: 8px solid var(--cogno-blue-dark);
  border-radius: 8px;
  background-color: var(--cogno-white);
}

.secc_prin h2 {
    text-align: center;
    max-width: 500px;
}

.secc_prin p {
    text-align: justify;
    max-width: 500px;
}

.secc_prin_2 {
  height: 250px;
  max-width: 600px;
  margin: 20px auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--cogno-blue-dark);
  border-top: 8px solid var(--cogno-blue-lite);
  border-radius: 8px;
  background-color: var(--cogno-white);
}

  .secc_prin_2 h2 {
      text-align: center;
      max-width: 500px;
  }

  .val-cod {
      min-width: 300px;
  }

.input {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px auto;
  border: 0.2px solid var(--cogno-blue-dark);
  max-width: 600px;
  border-radius: 8px;
  height: 150px;
  background-color: var(--cogno-white);
}

span {
  color: red;
}

.input_doc {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px auto;
  border: 0.2px solid var(--cogno-blue-dark);
  max-width: 600px;
  border-radius: 8px;
  height: 150px;
  background-color: var(--cogno-white);
}

.input_envio {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  margin: 10px auto;
  border: 0.2px solid var(--cogno-blue-dark);
  max-width: 600px;
  border-radius: 8px;
  height: 150px;
  background-color: var(--cogno-white);
}

.btn_enviar{
    width: 155px;
    border: none;
    border-radius: 25px;
    padding: 8px;
    background-color: var(--cogno-blue-dark);
    color: var(--cogno-white);
    cursor: pointer;
    opacity: 0.5;
}

.btn_enviar_form {
    width: 155px;
    border: none;
    border-radius: 25px;
    padding: 8px;
    background-color: var(--cogno-blue-dark);
    color: var(--cogno-white);
    cursor: pointer;
}

.btn_enviar img {
    width: 20px;
    height: 20px;
}

  .input_envio .btn_borrar {
      border: none;
      background-color: transparent;
      color: red;
      cursor: pointer;
  }

.contMsg {
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  padding-left: 20px;
  margin: 10px auto;
  border: 0.2px solid var(--cogno-blue-dark);
  max-width: 600px;
  border-radius: 8px;
  min-height: 150px;
  color: var(--cogno-white);
  padding: 10px;
}

.custom-file-label {
    background-color: #dddbd5;
    width: 200px;
    color: var(--cogno-dark);
    text-align: center;
    padding: 8px;
    border-radius: 25px;
    cursor: pointer;
}
