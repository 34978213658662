:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.bodyCoursesCourses {
  margin-top: 130px;
  width: 100%;
  min-height: 100vh;
}