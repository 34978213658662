:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.navBar {
  display: flex;
  position:absolute ;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  border-bottom: 1.5px solid var(--cogno-blue-dark);
  box-shadow: 0px 6px 6px var(--cogno-blue-dark);
  z-index: 1000;
  background-color: var(--cogno-white);
  opacity: 0.9;
  width: 100%;
  top: 0;
  left: 0;
}

.navBarIzq {
  display: flex;
}

.cogno-logo-navbar img{
  cursor: pointer;
}

.cogno-logo-navbar img{
  height: 100px;
}


.navBar__links {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navBar__links a {
  text-decoration: none;
  color: var(--cogno-dark);
  font-size: 17px;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBar__links a:hover {
  background-color: var(--cogno-blue-lite);
  font-weight: bold;
  color: var(--cogno-white);
}

.btnForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnForm button{
  width: 150px;
  height: 44px;
  border-radius: 12px;
  border: none;
  box-shadow: 4px 4px 12px var(--cogno-blue-dark);
  background-color: var(--cogno-blue-dark);
  color: var(--cogno-white);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.btnForm button:hover {
  color: var(--cogno-white);
  background-color: var(--cogno-blue-lite);
}

.container-iframe-navbar {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.container-iframe-navbar .container-form-registro {
  width: 100%;
  height: 100%;
}