:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.navBarResponsive {
  background-color: white;
  border-bottom: 1.5px solid var(--aiex-yellow);
}

.navBarResponsive__header {
  display: flex;
  height: 120px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.navBarResponsive__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.onac-logo-navbar img,
.aiex-logo-navbar img{
  cursor: pointer;
}

.aiex-logo-navbar img{
  height: 80px;
}

.onac-logo-navbar img{
  height: 95px;
}

.menu-toggle {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.navBarResponsive__links {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;
}

.navBarResponsive__links a {
  text-decoration: none;
  color: var(--aiex-dark);
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.navBarResponsive__links a:hover {
  background-color: var(--aiex-yellow);
  color: var(--aiex-dark);
}

.btnFormRes button {
  margin: 10px auto;
  width: 90%;
  height: 44px;
  border-radius: 12px;
  border: none;
  background-color: var(--cogno-blue-dark);
  color: var(--cogno-white);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

