:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.footer {
  background-color: var(--cogno-blue-dark);
  color : var(--cogno-white);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  font-size: 17px;
  min-height: 90vh;
}

.cont-area {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
}

.cont-acred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cont-img {
  display: flex;
  justify-content: center;
}

.cont-logo-footer {
  background-color: var(--cogno-white);
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-logo-footer img{
  height: 150px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 25%;
}

.logo-footer-onac img {
  width: 180%;
  height: 100%;
}

.redes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.red-icon {
  color: var(--cogno-white);
  font-size: 30px;
  cursor: pointer;
}

.red-icon:hover {
  color: var(--cogno-yellow);
}

.cont-map iframe {
  border: none;
  box-shadow: 2px 2px 10px var(--cogno-white);
  border-radius: 8px;
}

.cont-links {
  text-align: left;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cont-links p,
.cont-links a {
  color: var(--cogno-white);
  text-decoration: none;
  cursor: pointer;
}

.cont-links p:hover,
.cont-links a:hover {
  color: var(--cogno-yellow);
}