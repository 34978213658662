:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.bodyHomeAwards {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 70vh;
  width: 100%;
  background-color: var(--cogno-blue-lite);
  color: var(--cogno-white);
}

.bodyHomeAwards h2 {
  text-align: center;
}

.awards2012,
.awards2013 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.awards2012 img,
.awards2013 img {
  width: 380px;
  border-radius: 12px;
}