:root {
  --cogno-blue-dark: #212c60;
  --cogno-blue-lite: #7981be;
  --cogno-yellow: #efb325;
  --cogno-white: #FFFFFF;
  --cogno-dark: #000000;
}

.bodyHomeSlider {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-back-HomeSlider {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -3;
}

.img-back-HomeSlider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-back-HomeSlider img.animated-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomIn 10s ease-in-out infinite;
  transition: opacity 1s ease-in-out;
}

.containerHomeSlider {
  height: 100%;
  width: 100%;
}

.leftHomeSlider img {
  width: 350px;
}

.rightHomeSlider,
.leftHomeSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.descriptionHomeSlider {
  width: 320px;
  height: 320px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--cogno-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0.8;
}

.descriptionHomeSlider button{
  width: 210px;
  font-weight: bold;
  background-color: var(--cogno-blue-lite);
  color: var(--cogno-white);
  border-radius: 12px;
  border: none;
  font-size: 12px;
  box-shadow: 4px 5px 8px var(--cogno-blue-lite);
  padding: 8px;
  cursor: pointer;
  z-index: 1000 !important;
}

.circuleHomeSlider1 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  top: -5%;
  left: -5%;
  z-index: -1;
}

.circuleHomeSlider2 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  top: -20%;
  left: 10%;
  z-index: -1;
}

.circuleHomeSlider3 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  top: 12%;
  left: -15%;
  z-index: -1;
}

.circuleHomeSlider4 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-lite);
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: -8%;
  z-index: -2;
}

.circuleHomeSlider5 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-lite);
  border-radius: 50%;
  position: absolute;
  top: -5%;
  left: 6%;
  z-index: -2;
}

.circuleHomeSlider7 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  right: 15%;
  bottom: -10%;
  z-index: -1;
}

.circuleHomeSlider8 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  right: 25%;
  bottom: -30%;
  z-index: -1;
}

.circuleHomeSlider9 {
  width: 400px;
  height: 400px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  right: -8%;
  bottom: -8%;
  z-index: -1;
}

.circuleHomeSlider10 {
  width: 400px;
  height: 400px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  right: -12%;
  bottom: 15%;
  z-index: -1;
}

.circuleHomeSlider11 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-lite);
  border-radius: 50%;
  position: absolute;
  right: 8%;
  bottom: 15%;
  z-index: -2;
}

.circuleHomeSlider12 {
  width: 350px;
  height: 350px;
  background-color: var(--cogno-blue-lite);
  border-radius: 50%;
  position: absolute;
  right: 20%;
  bottom: -5%;
  z-index: -2;
}

.circuleHomeSlider13 {
  width: 400px;
  height: 400px;
  background-color: var(--cogno-blue-dark);
  border-radius: 50%;
  position: absolute;
  right: 0%;
  bottom: -15%;
  z-index: -1;
}

/* Definición de la animación para el efecto de acercamiento */
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Ajusta este valor según el nivel de acercamiento deseado */
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1100px) {
  
  .circuleHomeSlider1 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider2 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider3 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider4 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider5 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider7 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider8 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider9 {
    width: 300px;
    height: 300px;
  }
  
  .circuleHomeSlider10 {
    width: 300px;
    height: 300px;
  }
  
  .circuleHomeSlider11 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider12 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider13 {
    width: 300px;
    height: 300px;
  }

}

@media screen and (max-width: 600px) {
  
  .circuleHomeSlider1 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider2 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider3 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider4 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider5 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider7 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider8 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider9 {
    width: 250px;
    height: 250px;
  }
  
  .circuleHomeSlider10 {
    width: 200px;
    height: 200px;
  }
  
  .circuleHomeSlider11 {
    width: 150px;
    height: 150px;
  }
  
  .circuleHomeSlider12 {
    width: 200px;
    height: 200px;
  }
  
  .circuleHomeSlider13 {
    width: 200px;
    height: 200px;
  }

}

@media screen and (max-width: 480px) {
  .circuleHomeSlider1,
  .circuleHomeSlider2,
  .circuleHomeSlider3,
  .circuleHomeSlider4,
  .circuleHomeSlider5,
  .circuleHomeSlider7,
  .circuleHomeSlider8,
  .circuleHomeSlider9,
  .circuleHomeSlider10,
  .circuleHomeSlider11, 
  .circuleHomeSlider12, 
  .circuleHomeSlider13 {
    display: none;
  }
}